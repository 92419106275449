import React from "react"

import GetInTouch from "../Contact/GetInTouch"
import ContactUsForm from "../Contact/ContactUsForm"

export default function Contact() {
  return (
    <div>
      <GetInTouch />
      <ContactUsForm />
    </div>
  )
}
